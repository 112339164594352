

















/** {@link} https://feathericons.com/ */
import Vue from 'vue';

export default Vue.extend({
  name: 'SvgInbox',
  props: {
    size: {
      type: [Number, String],
      required: false,
      default: 24,
    },
  },
  data() {
    return {
      d: 'M5.45 5.11L2 12v6a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2v-6l-3.45-6.89A2 2 0 0 0 16.76 4H7.24a2 2 0 0 0-1.79 1.11z',
    };
  },
});
